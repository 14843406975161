import { PaymentMethod } from "lib/graphql"
import { PAYMENT_METHODS } from "lib/static/paymentMethod"
import { useBetterTranslation } from "./useTranslation"

export function usePaymentMethodOptions() {
  const bt = useBetterTranslation()
  return Object.keys(PaymentMethod).map((paymentMethod: keyof typeof PaymentMethod) => ({
    value: PaymentMethod[paymentMethod],
    label: bt({
      en: PAYMENT_METHODS[PaymentMethod[paymentMethod]].en,
      nl: PAYMENT_METHODS[PaymentMethod[paymentMethod]].nl,
    }),
  }))
}
